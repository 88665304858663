<template>
<drivers/>
</template>

<script>
 import Drivers from '../components/Drivers'
  export default {
     components: { Drivers },
    data: () => ({
      loading: false,
      selection: 1,
    }),

    methods: {
      reserve () {
        this.loading = true

       // setTimeout(() => (this.loading = false), 2000)
      },
    },
  }
</script>
