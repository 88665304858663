<template>
<v-container class="grey lighten-5">
  <new-driver/>
     <driver v-for="item in driverList" :key="item.id" :item=item />
</v-container>
   
</template>

<script>
 import Driver from './Driver'
 import {mapState} from 'vuex'
import NewDriver from './NewDriver.vue';
export default {
  components: { Driver, NewDriver },
  computed: {
   ...mapState(['driverList'])
  },
     created() {
    this.$store.dispatch('loadDrivers');
  },

}
</script>

<style>

</style>