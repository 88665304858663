<template>
 <v-expansion-panels  v-model="panel" multiple>
    <v-expansion-panel >
      <v-expansion-panel-header>
        گەران
      </v-expansion-panel-header>
      <v-expansion-panel-content>
       <v-row justify="center">
    <v-col
     
      xs="10"
      sm="10"
    
      md="4"
      lg="6"
    >
      <v-card ref="form">
        <v-card-text>
          <!-- <v-menu
        ref="menu3"
        v-model="menu3"
        :close-on-content-click="false"
        :return-value.sync="startDate"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="startDate"
            label="Picker in menu"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="startDate"
          no-title
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="menu3 = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.menu3.save(startDate)"
          >
            OK
          </v-btn>
        </v-date-picker>
          </v-menu>
      <v-menu
        ref="menu1"
        v-model="menu1"
        :close-on-content-click="false"
        :return-value.sync="endDate"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="endDate"
            label="Picker in menu"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="endDate"
          no-title
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="menu1 = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.menu1.save(endDate)"
          >
            OK
          </v-btn>
        </v-date-picker>
          </v-menu> -->
           <v-card-text>
          <v-text-field
            ref="transId"
            v-model="transId"
          
            label="Id"
            placeholder="Id"
            required
          ></v-text-field>
          <v-text-field
            ref="phone"
            v-model="phone"
          
            label="Phone"
            placeholder="Phone"
            required
          ></v-text-field>
          <v-autocomplete
                v-if="userType == 'admin'"
                v-model="status"
                :items="myAllStatus"
                item-text="status"
                item-value="id"
                outlined
                dense
                chips
                small-chips
                label="بارودوخ"
                
          ></v-autocomplete>
          <v-autocomplete
          v-if="userType == 'admin'"
                v-model="city"
                :items="myCities"
                item-text="city"
                item-value="city"
                outlined
                dense
                chips
                small-chips
                label="شار"
                
          ></v-autocomplete>
            <v-autocomplete
            v-if="userType == 'admin'"
                v-model="userId2"
                :items="dukandars"
                item-text="dukandarName"
                item-value="id"
                outlined
                dense
                chips
                small-chips
                label="dukandar"
                
          ></v-autocomplete>
           <v-autocomplete
           v-if="userType == 'admin'"
                v-model="driverId"
                :items="drivers"
                item-text="driverName"
                item-value="id"
                outlined
                dense
                chips
                small-chips
                label="شۆفير"
                
          ></v-autocomplete>
          <v-switch
          v-if="userType == 'admin'"
              v-model="compeleted"
              label="تەواوبوە"
          ></v-switch>
        </v-card-text>
        </v-card-text>
        <v-divider class="mt-12"></v-divider>
        <v-card-actions>
 
          <v-spacer></v-spacer>
        
          <v-btn
            color="primary"
            text
            @click="onChildClick"
          >
            گەران
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>

  </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
        <v-expansion-panel >
      <v-expansion-panel-header>
        ئەنجام
      </v-expansion-panel-header>
      <v-expansion-panel-content>
       <row :ordersList="searchOrders"/>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
  
</template>
<script>
import Row from './Row.vue';
import {mapActions, mapState} from 'vuex'
  export default {
  components: { Row },
    data: () => ({
      panel:[0],
      name: null,
      phone:'',
      startDate: null,
      endDate: null,
      status: -1,
      transId: '',
      compeleted: false,
      items: 2,
      menu3: false,
      menu1: false,
      city:'',
      userId2: '',
      driverId: 0

    }),

    computed: {
        ...mapState([ 'userType', 'myStatus', 'orderItem', 'userId', 'userName', 'cities', 'dukandarList', 'driverList']),
        searchOrders() {
          return this.orderItem
        },
        myAllStatus() {
            
            return this.myStatus
        },
           myCities() {
          return this.cities
        },
           dukandars() {
          return this.dukandarList
        },
        drivers() {
          return this.driverList
        },
      form () {
        return {
          name: this.name,

        }
      },
    },


    methods: {
        ...mapActions(['getTransportByPhone', 'resetSearchItemOrder', 'loadCities', 'loadDDukandars', 'loadDrivers']),
        onChildClick () {
           // if (this.userType == 'admin') {
             console.log(this.userId);
                this.getTransportByPhone({
                  "phone":this.phone,
                  "transId": this.transId,
                  "compeleted": this.compeleted,
                  "dukandarId": this.userId2,
                  "cityName": this.city,
                  "status": this.status,
                  "startDate": this.startDate,
                  "endDate": this.endDate,
                  "driverId": this.driverId,
                  "userType": this.userType,
                  "id": this.userId==0? this.userName: this.userId.toString()

                     })
                      this.panel =  [1, 2, 3]
               // console.log('Im admin');
           // }
           
          //  else  {
          //    this.phoneNumber= this.phone
          //  }
        },     
     
      submit () {
        this.panel =  [1, 2, 3]
      
      },
    },
    created() {
      this.loadCities();
        this.loadDDukandars()
        this.loadDrivers()
        this.myStatus.unshift({'id': -1, 'status': 'any'})
    }
  }
</script>