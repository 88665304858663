<template>
 <v-card
    :loading="loading"
    class="mx-auto my-12"
    max-width="374"
  >
    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>

 
    <v-card-title>{{item.driverName}}   </v-card-title>

    <v-card-text>
      <v-row
        align="center"
        class="mx-0"
      >
   <div class="my-4 text-subtitle-1">
           <v-text-field
            ref="password"
            v-model="password"
           :rules="[() => !!password || 'This field is required']"
            :error-messages="errorMessages"
            label="Driver Password"
            placeholder="Password"
            required
          ></v-text-field>
       <v-text-field
            ref="mobile"
            v-model="item.mobile"
          
            label="Driver Mobile"
            placeholder="Mobile"
            required
          ></v-text-field>
      </div>

     
      </v-row>

   
        <v-btn
        :disabled= IsDisabled
        color="primary"
        elevation="2"
        @click="update"
        >Update</v-btn>


      <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
     >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="warning"
          dark
          v-bind="attrs"
          v-on="on"
          @click="clickMeToReadData"
        >
          كشف
        </v-btn>
      </template>
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Settings</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
              @click="dialog = false"
            >
              Save
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-list
          three-line
          subheader
        >
         
          <!-- <v-subheader>General</v-subheader> -->
          <row   :ordersList='newOrderList' />
         
        </v-list>
      </v-card>
    </v-dialog>


    </v-card-text>

    <v-divider class="mx-4"></v-divider>

   

    <!-- <v-card-text>
      <v-chip-group
        v-model="item.status"
        active-class="deep-purple accent-4 white--text"
        column
      >
        <v-chip @click="selectMe(item.id, 0)">هيچ</v-chip>

        <v-chip @click="selectMe(item.id, 3)">وەرمگرت</v-chip>

        <v-chip @click="selectMe(item.id, 4)">رفض</v-chip>

        <v-chip @click="selectMe(item.id, 5)">مؤجل</v-chip>
      </v-chip-group>
    </v-card-text> -->


  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Row from './Row.vue'
  export default {
    components: {
      Row
    },
    data: () => ({
      loading: false,
      password: '',
      mobile:'',
      errorMessages: '',
       dialog: false,
        notifications: false,
        sound: true,
        widgets: false,
    
    }),
    props: {
      item: Object,
    },

    methods: {
      ...mapActions(['updatePassword', 'getDriverAllTransport']),
      update() {
        this.updatePassword({id: this.item.id, password:this.password, mobile: this.item.mobile})
         
      },
      clickMeToReadData() {
       this.getDriverAllTransport(this.item.id)
      },
      reserve () {
        this.loading = true

       // setTimeout(() => (this.loading = false), 2000)
      },

    },
    computed: {
      ...mapState(['userId', 'ordersList']),
      IsDisabled () {
          return !this.password.length>0 && !this.item.mobile.length>0
      },
      newOrderList() {
        return this.ordersList
      }
    }
  }
</script>

