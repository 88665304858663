<template>
  <v-form>
    <v-container>
      <v-row>
        <v-col cols="12" sm="3">
          <v-text-field v-model="muOrder.customerName"  label="کریار"  outlined></v-text-field>
        </v-col>
        <v-col cols="12"  sm="3">
     <v-menu
        ref="menu2"
        v-model="menu2"
        :close-on-content-click="false"
        :return-value.sync="muOrder.receivingDate"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="muOrder.receivingDate"
            label="Picker in menu"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="muOrder.receivingDate"
          no-title
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="menu2 = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.menu2.save(muOrder.receivingDate)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
          <!-- <v-text-field   v-model="muOrder.receivingDate"  label="بەرواری هینان"   outlined></v-text-field> -->
        </v-col>
        <v-col cols="12"  sm="3">
          <v-text-field type="number" v-model.number="muOrder.amount"  label="پارە"  outlined></v-text-field>
        </v-col>
        <v-col  cols="12" sm="3">
          <v-text-field  v-model="muOrder.mobile"  label="موبايل"  outlined></v-text-field>
          <!-- <v-text-field  v-model="muOrder.driverClearnce"  label="پاک کراەوە؟"  outlined></v-text-field> -->
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="3">
          <v-text-field v-model="muOrder.address"  label="ناونیشان"  outlined></v-text-field>
        </v-col>
        <v-col cols="12"  sm="3">
 <v-menu
        ref="menu3"
        v-model="menu3"
        :close-on-content-click="false"
        :return-value.sync="muOrder.shippingDate"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="muOrder.shippingDate"
            label="Picker in menu"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="muOrder.shippingDate"
          no-title
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="menu3 = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.menu3.save(muOrder.shippingDate)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
          <!-- <v-text-field   v-model="muOrder.shippingDate"  label="بەرواری دەرچون"   outlined></v-text-field> -->
        </v-col>
        <v-col cols="12"  sm="3">
          <v-text-field type="number"  v-model.number="muOrder.commission"  label="نرخ"  outlined></v-text-field>
        </v-col>
        <v-col  cols="12" sm="3">
            <v-autocomplete
                v-model="muOrder.status"
                :items="myStatus"
                item-text="status"
                item-value="id"
                outlined
                dense
                chips
                small-chips
                label="بارودوخ"
                
          ></v-autocomplete>
          <!-- <v-text-field  v-model="muOrder.status"  label="باردۆخ"  outlined></v-text-field> -->
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="3">
          <v-text-field v-model="muOrder.driverMobile" disabled label="موبایلي شوفير"  outlined></v-text-field>
        </v-col>
        <v-col cols="12"  sm="3">
             <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="muOrder.receivingDate"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="muOrder.receivingDate"
            label="Picker in menu"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="muOrder.receivingDate"
          no-title
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="menu = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.menu.save(muOrder.receivingDate)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
        </v-col>
        <v-col cols="12"  sm="3"> 
     
             <v-switch
              v-model="muOrder.compeleted"
              label="تەواوبوە"
          ></v-switch>
          <!-- <v-text-field  v-model="muOrder.compeleted"  label="تەواوبوە"  outlined></v-text-field> -->
        </v-col>
        <v-col  cols="12" sm="3">
            <v-autocomplete
                v-model="muOrder.city"
                :items="myCities"
                item-text="city"
                item-value="city"
                outlined
                dense
                chips
                small-chips
                label="شار"
                
          ></v-autocomplete>
          <!-- <v-text-field  v-model="muOrder.city"  label="شار"  outlined></v-text-field> -->
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="3">
           <v-autocomplete
                v-model="muOrder.userId"
                :items="dukandars"
                item-text="dukandarName"
                item-value="id"
                outlined
                dense
                chips
                small-chips
                label="dukandar"
                
          ></v-autocomplete>
        </v-col>
        <v-col cols="12"  sm="3">
            <v-autocomplete
                v-model="muOrder.driverId"
                :items="drivers"
                item-text="driverName"
                item-value="id"
                outlined
                dense
                chips
                small-chips
                label="شۆفير"
                
          ></v-autocomplete>
        </v-col>
        <v-col cols="12"  sm="3"> 
    
           <v-switch
              v-model="muOrder.driverClearnce"
              label="پاک کراەوە؟"
          ></v-switch>
        </v-col>
        <v-col  cols="12" sm="3">
            <v-text-field type="text"  v-model="muOrder.packageName"  label="تیبینی"  outlined></v-text-field>
       
          <!-- <input v-model="muOrder.receivingDate"/> -->
        </v-col>
      </v-row>
                <!-- {{$route.params.id}} -->
          <v-btn
            color="primary"
            elevation="2"
            outlined
            @click="saveMyOrder"
        >Save</v-btn>
          <v-btn
            color="primary"
            elevation="2"
            outlined
            @click="deleteTheOrder"
        >Delete</v-btn>
        <v-btn
            color="primary"
            elevation="2"
            outlined
            @click="clearDriverorder"
        >پاکانەی شۆفێر</v-btn>
    </v-container>
    
   <v-snackbar
      v-model="snackbar"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-form>
</template>

<script>
import {mapActions, mapState} from 'vuex'
  export default {
    data: () => ({
      orderItem2: Object,
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        date3: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        menu: false,
        modal: false,
        menu1: false,
        menu2: false,
        menu3: false,
        snackbar: false,
        text:  `Saved Succfully`,
        city:'',
        picker: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    //   last: 'Doe',
    }),
    methods : {
        ...mapActions(['getTransport', 'loadCities', 'loadDDukandars', 'loadDrivers', 'saveOrder', 'deleteOrder']),
        saveMyOrder() {
          this.orderItem[0].pakaneyDriver= false
          this.saveOrder()
          this.snackbar= true
        },
        deleteTheOrder() {
            if(confirm('دلنياي?')) {
            this.deleteOrder(this.$route.params.id)
            this.$router.push({ name: 'Search'})
            }
          
         },
        clearDriverorder() {
            this.orderItem[0].pakaneyDriver= true
            this.saveOrder()
             this.snackbar= true
            
        }
    },
    computed: {
        ...mapState(['orderItem', 'cities', 'myStatus', 'cities', 'dukandarList', 'driverList']),
        muOrder() {
            return this.orderItem[0]
        },
        myCities() {
          return this.cities
        },
        dukandars() {
          return this.dukandarList
        },
        drivers() {
          return this.driverList
        },

    },
    created () {
        this.getTransport(this.$route.params.id)
        this.loadCities();
        this.loadDDukandars()
        this.loadDrivers()
    //     this.orderItem2= this.orderItem
    //     console.log(this.orderItem2);
    }

  }
</script>