<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          style="margin-top: 20px"
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          دروستكردني دوكاندار
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">دوكاندار</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                   v-model="user.userName"
                  label="ناوي دوكاندار*"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="user.phoneNumber"
                  label="موبايل"
                  hint="موبايلي دوكاندار"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <!-- <v-text-field
                  label="Legal last name*"
                  hint="example of persistent helper text"
                  persistent-hint
                  required
                ></v-text-field> -->
              </v-col>
              <v-col cols="12">
                <!-- <v-text-field
                v-model="user.email"
                  label="Email*"
                  required
                ></v-text-field> -->
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="user.email"
                  label="Password*"
                  type="password"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <!-- <v-select
                  :items="['0-17', '18-29', '30-54', '54+']"
                  label="Age*"
                  required
                ></v-select> -->
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <!-- <v-autocomplete
                  :items="['Skiing', 'Ice hockey', 'Soccer', 'Basketball', 'Hockey', 'Reading', 'Writing', 'Coding', 'Basejump']"
                  label="Interests"
                  multiple
                ></v-autocomplete> -->
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="saveUser"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import { mapActions } from 'vuex'
  export default {
    data: () => ({
      dialog: false,
    }),
    props: {
        user: {
            type: Object,
             default: function () {
                return { 
                    userName: '',
                    email:'',
                    passwordHash:'',
                    phoneNumber: ''
                 }
            }
        }
    },
    methods: {
        ...mapActions(['saveCurrentDukandar']),
        saveUser() {
            this.saveCurrentDukandar(this.user)
            this.dialog= false
        }
    }
  }
</script>