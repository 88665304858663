<template>
<div class="container"> 
    <!-- <new-dukandar/> -->
    
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    <v-data-table
        :headers="headers"
        :items="dukandarList"
        sort-by="calories"
        class="elevation-1"
        :search="search"
        @click:row="handleClick"
    >
        <template v-slot:top>
        <v-toolbar
            flat
        >
            <v-toolbar-title>ليستي دوكاندار </v-toolbar-title>
            <v-divider  class="mx-4"  inset  vertical  ></v-divider>
            <v-spacer></v-spacer>
            <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          دروستكردني دوكاندار
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">دوكاندار</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                   v-model="editedItem.dukandarName"
                  label="ناوي دوكاندار*"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="editedItem.phoneNumber"
                  label="موبايل"
                  hint="موبايلي دوكاندار"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <!-- <v-text-field
                  label="Legal last name*"
                  hint="example of persistent helper text"
                  persistent-hint
                  required
                ></v-text-field> -->
              </v-col>
              <v-col cols="12">
                <!-- <v-text-field
                v-model="user.email"
                  label="Email*"
                  required
                ></v-text-field> -->
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="editedItem.email"
                  label="Password*"
                  type="password"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <!-- <v-select
                  :items="['0-17', '18-29', '30-54', '54+']"
                  label="Age*"
                  required
                ></v-select> -->
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <!-- <v-autocomplete
                  :items="['Skiing', 'Ice hockey', 'Soccer', 'Basketball', 'Hockey', 'Reading', 'Writing', 'Coding', 'Basejump']"
                  label="Interests"
                  multiple
                ></v-autocomplete> -->
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="saveUser"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
        <v-icon  small  class="mr-2"  @click="editItem(item)" > mdi-pencil  </v-icon>
        <v-icon small  @click="deleteItem(item)" >  mdi-delete    </v-icon>
        <router-link :to="{ name: 'DukandarKashf', params: { id: item.id }}">كشف</router-link>
        <router-link  :to="{ name: 'Invoices', params: { id: item.id }}"><v-icon class=" mdi-spin ">  mdi-kodi </v-icon></router-link>
       
        </template>
     
        
    </v-data-table>
   </div>
</template>

<script>

// import NewDukandar from '../components/NewDukandar.vue'
import { mapActions, mapState } from 'vuex'
// import Row from '../components/Row.vue'
export default {
    components: {
        // NewDukandar
         // Row
    },
    data: () => ({
      dialog: false,
      dialogDelete: false,
       search: '',
      headers: [
     
        { text: 'Dukandar', value: 'dukandarName' },
        { text: 'mobile', value: 'phoneNumber' },
        { text: 'password', value: 'email' },
        // { text: 'Carbs (g)', value: 'carbs' },
        // { text: 'Protein (g)', value: 'protein' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      desserts: [],
      editedIndex: -1,
      editedId: '',
      editedItem: {
        id: '',
        dukandarName: '',
        phoneNumber:'',
        email: '',
       
      },
      defaultItem: {
        id: '',
        dukandarName: '',
        phoneNumber: '',
        email: '',
        
      },
       NordersList: [
      {
        'id': 1,
        'status': 0,
        'mobile': '07504549598',
        'amount': 25000,
        'customerName': 'صالح جمعة',
        'address': 'هەوليرو گوندي ايتالي ٢',
        'deliveryDat':null
      },
 
    ],
    }),

    computed: {
      ...mapState(['dukandarList', 'ordersList']),
      formTitle () {
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
      },
       newOrderList() {
        return this.ordersList
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
     // this.initialize()
     this.loadDDukandars()
    },

    methods: {
      ...mapActions(['saveCurrentDukandar', 'getDukandarAllTransport', 'loadDDukandars', 'deleteUser']),
      saveUser() {
            this.saveCurrentDukandar(this.editedItem)
            this.dialog= false
      },
      clickMeToReadData(item) {
       // console.log('gfgfgf');
      console.log(item);
        //this.getDukandarAllTransport(item)
      },
      handleClick(value) {
        
            console.log(value);
        },

      editItem (item) {
        this.editedIndex = this.dukandarList.indexOf(item)
        this.editedItem = Object.assign({}, item)
       // console.log(this.editedItem);
        this.dialog = true
      },

      deleteItem (item) {
        //console.log('jhkhjkjh');
        //console.log(item);
        this.editedIndex = this.dukandarList.indexOf(item)
        //console.log(this.editedIndex);
        this.editedItem = Object.assign({}, item)
        this.editedId= item.id
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        //console.log(item);
        this.dukandarList.splice(this.editedIndex, 1)
        this.deleteUser(this.editedId)
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {
          
          Object.assign(this.dukandarList[this.editedIndex], this.editedItem)
        } else {
          this.saveCurrentDukandar(this.editedItem)
          this.dukandarList.push(this.editedItem)
        }
        this.close()
      },
    },
}
</script>

<style>

</style>