<template>
  <row :ordersList="newOrderList"/>
</template>

<script>
import Row from '../components/Row.vue'
import {mapActions, mapState} from 'vuex'
export default {
    components: {
        Row,
    },
    computed: {
        ...mapState(['ordersList']),
        newOrderList() {
            return this.ordersList
        }
    },
    methods: {
            ...mapActions(['getDukandarAllTransport'])
    }, 
    created() {
          var id=  this.$route.params.id
          this.getDukandarAllTransport(id)
    }
}
</script>


<style>

</style>