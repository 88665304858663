<template>
   <v-form>
    <div style="margin-bottom: -45px; margin-right:10px;">

      <v-row>
         <v-col cols="12" sm="6" >
           <v-row>
              <v-col cols="5" sm="2" class="col-margin">
                 <v-text-field v-model="orderItem.id"  label="#"  outlined></v-text-field>
              </v-col>
              <v-col cols="5" sm="3" class="col-margin">
                 <v-text-field v-model="orderItem.customer"  label="کریار"  outlined></v-text-field>
              </v-col>
              <v-col cols="5" sm="3" class="col-margin">
                   <v-autocomplete
                      v-model="orderItem.city"
                      :items="cityList"
                      item-text="city"
                      item-value="city"
                      clearable
                      outlined
              
        
                  ></v-autocomplete>
              </v-col>
              <v-col cols="5" sm="4" class="col-margin">
                 <v-text-field v-model="orderItem.address"  label="ناونيشان"  outlined></v-text-field>
              </v-col>
           </v-row>
         </v-col>
         <v-col cols="12" sm="6" >
            <v-row>
              <v-col cols="5" sm="3" class="col-margin">
                 <v-text-field v-model="orderItem.mobile"  label="موبايل"  outlined></v-text-field>
              </v-col>
              <v-col cols="5" sm="2" class="col-margin">
                 <v-tooltip bottom color="success">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model.number="orderItem.price"  type="number"  label="نرخ"  v-bind="attrs"
          v-on="on"  outlined></v-text-field>
                    </template>
                     <span> {{orderItem.price | currency}} </span>
                </v-tooltip>


              </v-col>
              <v-col cols="5" sm="2" class="col-margin">
                 <v-text-field v-model.number="orderItem.commisson" type="number"  label="عمولە"  outlined></v-text-field>
              </v-col>
               <v-col cols="5" sm="2" class="col-margin">
                 <v-btn style="padding: 0 6px"   label="بەروار"  outlined>{{orderItem.recievedDate | date}} </v-btn>
              </v-col>
              <v-col cols="5" sm="2" class="col-margin">
                 <v-select
                  v-model.number="orderItem.status"
                  :items="items"
                  item-text="status"
                  item-value="id"
                  label="status*"
                  outlined
                dense
                  required
            ></v-select>
              </v-col>
              <v-col cols="5" sm="1" class="col-margin">
                  <v-btn
                      class="mx-2"
                      fab
                      dark
                      small
                      color="primary"
                      @click="removeOrderItem"
                  >
                  <v-icon dark>
                      mdi-minus
                  </v-icon>
            </v-btn>
              </v-col>
           </v-row>
         </v-col>
      </v-row>


                <!-- {{$route.params.id}} -->
      
    </div>
   <v-snackbar
      v-model="snackbar"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-form>
</template>

<script>
import {mapActions, mapState} from 'vuex'
export default {
  data: () => ({
    text: '',
    items:[
      {
        id:0,
        status:'New'
      },
      {
        id:1,
        status:'Shipped'
      },
      {
        id:2,
        status:'ARRIVED'
      },
      {
        id:3,
        status:'Paid'
      },
      {
        id:4,
        status:'Reject'
      },
      {
        id:5,
        status:'Pending'
      },
      {
        id:6,
        status:'Ready'
      },

    ]
    }),
    props: {
        orderItem:Object
    },
    methods: {
        ...mapActions(['removeOrderItemAction']),
        removeOrderItem() {
           this.removeOrderItemAction(this.orderItem.id)
        }
    },
    computed: {
      ...mapState(['cities']),
      cityList() {
        return this.cities
      }
    },
     filters: {
      currency: function (value) {
        if (typeof value !== "number") {
        return value;
        }
        var formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'IQD'
        });
       return formatter.format(value);
      },
     date: function(theDate) {
       return  theDate==null? '': new Date(theDate).toLocaleDateString("en-US")
     }
    }
}
</script>

<style scoped>
.col-margin {
    padding-left: 1px;
    padding-right: 1px;
}
</style>