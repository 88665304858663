<template>
 <v-container class="grey lighten-5">

  <login v-if="!isLoged" />
  <div v-if="isLoged && !isItAdmin">
     <Orders :buttons='isItDriver' />
  </div>
 
  <div v-if="isItAdmin">
    <Drivers/>
  </div>
 
 </v-container>
</template>

<script>
// this view will list all drivers name with password changing
  import Login from '../components/Login'
 
  
  import Drivers from '../components/Drivers'
  import Orders from '../components/Orders'
  import {mapState, mapActions} from 'vuex'
  export default {

    data () {
      return {
         selectedStatus: '',
         selection: 0,
      }
    
    },
    methods: {
    ...mapActions(['resetDukandaeNameAndStatusForAdmin'])
    },
    computed: {
       ...mapState(['token', 'userId', 'userType']),
      
      isLoged () {
        return !!this.token
      },
      isItAdmin() { 
        return this.userType == "admin"
      },
      isItDriver() {
          return this.userType== 'driver'
      }
        
    },
    components: {
      Login,
      Drivers,
      Orders
    },
  
     created() {
    this.resetDukandaeNameAndStatusForAdmin()
    this.$store.dispatch('loadPosts');
  },
  }
</script>
