<template>
  <div>
      <v-row>
          <v-col>
              <v-row>
                  <v-col>
                    <v-autocomplete
                        v-model="cityId"
                        :items="cities"
                        item-text="city"
                        item-value="id"
                        clearable
                        label="شارەكان"
                    ></v-autocomplete>
                  </v-col>
                  <v-col>
                    <v-btn style="margin-top: 15px;"
                        color="primary"
                        elevation="2"
                        outlined
                        @click="bringOrderBelongToCity"
                        >
                        بابەت بهينە
                    </v-btn>
                  </v-col>
                
              </v-row>
           
         
          </v-col>
    
          <v-col>
               <v-autocomplete
                v-model="driverId"
                :items="driverList"
                item-text="driverName"
                item-value="id"
                label="شۆفير"
                clearable
              ></v-autocomplete>
          </v-col>
      </v-row>

  
         
          <OrderItem  v-for="itemList in myListForDriver" :key="itemList.id" :orderItem=itemList />
          <v-btn class="primary"> {{myListForDriver.length}} دانە </v-btn>
         <v-btn
            color="primary"
            elevation="2"
            outlined
            :disabled="addToDriverStatus"
            @click="addOrderToDriver"
            >
            زياد بكە بو شۆفير
        </v-btn>

    <v-snackbar
      v-model="snackbar"
     
      top
      timeout="2000"
    color="success"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">

        <v-btn

          color="red"
          text
          v-bind="attrs"
         
        >
          Saved!
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
    
        <v-card>
          <!-- <div class="container">   -->
          <v-toolbar  dark  color="primary">
            <v-btn  icon  dark  @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
               <v-spacer></v-spacer>
            <v-toolbar-title style="margin-left:10px">قائمە بۆ شوفیر</v-toolbar-title>
            <v-toolbar-title>{{returnDriverName}}</v-toolbar-title>
            <v-toolbar-title style="margin-right:10px">{{new Date().toJSON().slice(0,10).replace(/-/g,'/')}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn
                dark
                text
                @click="dialog = false"
              >
                Close
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
            <v-data-table
                :headers="headers"
                :items="listForDukandarForPrint"
                :items-per-page="-1"
                class="elevation-1"
                   :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-minus',
        nextIcon: 'mdi-plus',
           'items-per-page-text':'products per page'
      }"
              ></v-data-table>
      <!-- </div> -->
        </v-card>
      
    </v-dialog>


  </div>
</template>

<script>
import OrderItem from '../components/OrderItem'
import {mapState, mapActions, mapGetters} from 'vuex'

export default {
    components: {
        OrderItem
    },
    data: () => ({
      cityId: 0,
      dialog: false,
      driverId: 0,
      value: 0,
      text : "Saved Succfully!",
        headers: [
           { text: 'id', value: 'id' },
          {
            text: 'كريار',
            align: 'start',
            sortable: false,
            value: 'customer',
          },
          { text: 'شار', value: 'city' },
          
          { text: 'موبايل', value: 'mobile' },
          { text: 'پارە', value: 'price' },
          { text: 'كري', value: 'commisson' },
          { text: 'ناونيشان', value: 'address' },
          // { text: 'دوكاندار', value: 'dukandar' },
          // { text: 'ريكەوت', value: 'recievedDate' },
          // { text: 'شۆفير', value: 'driverName' },
          // { text: 'موبايلي', value: 'driverMobile' },
          
        ],

    }),
    computed: {
       ...mapState(['cities', 'driverList', 'listForDukandar', 'showSnackbar', 'listForDukandarForPrint']) ,
       ...mapGetters(['getDriverNameById']),
       myListForDriver() {
           return this.listForDukandar
       },
          snackbar() {
         return this.showSnackbar
     },
      returnDriverName() {
          return this.getDriverNameById(this.driverId)
        },

       addToDriverStatus() {
           //console.log(this.myListForDriver);
           return this.driverId==0 || this.listForDukandar.length <1
       }
    },
    methods: {
        ...mapActions(['addOrderToDriverAction', 'loadDrivers', 'bringOrderBelongToCityAction', 'loadCities', 'setIsItListForDukandarToFalse']),
        bringOrderBelongToCity() {
            this.bringOrderBelongToCityAction(this.cityId)
        },
        addOrderToDriver() {
            //console.log('hi');

           this.addOrderToDriverAction(this.driverId)
           this.dialog= true
        }
    },
    mounted() {
        this.setIsItListForDukandarToFalse()
        this.loadCities()
        this.loadDrivers();
    }
}
</script>

<style>

</style>