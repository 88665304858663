<template>
<div>
  <v-card
    class="mx-auto"
    max-width="400"
  >
    <v-img
      class="white--text align-end"
      height="200px"
      src="https://www.gpstracker.at/wp-content/uploads/2019/03/How-GPS-Tracking-Can-Benefit-Delivery-Services.jpg"
    >
      <v-card-title style="color:black"> {{invoice.myDate | date}}</v-card-title>
      
    </v-img>

    <v-card-subtitle class="pb-0">
    <!-- <v-btn style="float:center"> {{invoice.myDate | date}} </v-btn> -->
    </v-card-subtitle>

    <v-card-text class="text--primary">
      <v-btn >{{invoice.dateCount}}  ئۆردەر</v-btn>

      <v-btn @click="detailsClick" style="float:left">{{invoice.dateSum}}کۆی پارە</v-btn>
    </v-card-text>

    <v-card-actions>
      <!-- <v-btn
        color="orange"
        text
        @click="detailsClick"
      >
        PDF
      </v-btn> -->

      <!-- <v-btn
        color="orange"
        text
        @click="detailsClick"
       
      >
        Details
      </v-btn> -->
    </v-card-actions>
  </v-card>

  <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >

      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title>  فاتورة  ژمارە  {{ getInvoiceNumber() }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
              @click="dialog = false"
            >
              Save
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
     
          <!-- <v-subheader>User Controls</v-subheader> -->
        <row :ordersList="newList" />
      </v-card>
    </v-dialog>
</div>

  
</template>

<script>
import Row from './Row.vue'
import { mapActions, mapState} from 'vuex'
const Hashids = require("hashids")
export default {
  components: { Row },
  data () {
      return {
        dialog: false,
         Hashids: null,
        notifications: false,
        sound: true,
        widgets: false,
      }
    },
    props: {
        invoice: Object,
        myUserIdProps:String
    },
    methods: {
      ...mapActions(['getDukandarorderByDate']),
      detailsClick() {
        //console.log(this.invoice);
        this.getDukandarorderByDate({'date': this.invoice.myDate, 'userId': this.myUserIdProps })
         this.dialog = true
      },
        getInvoiceNumber() {
         // console.log(this.returnOnlyDate());
        //var hash = new Hashids('My Project')
        var ttt= this.returnOnlyDate()
        var tt = ttt.replace('/', '')
         tt = tt.replace('/', '')
        var t= this.Hashids.encode(tt) 
       //console.log(tt);
        return t
      },
      returnOnlyDate() {
       return new Date(this.invoice.myDate).toLocaleDateString("en-US")
      }
    },
    computed: {
      ...mapState(['userName', 'newList']),
    
    },
      filters: {
       date: function(theDate) {
       return  theDate==null? '': new Date(theDate).toLocaleDateString("en-US")
     }
    },
     mounted() {
        this.Hashids = new Hashids.default(this.userName)
    }
}
</script>

