<template>
    <div>

    <search v-on:getPhoneNumber="onChildClick" />
  <!-- <Item v-for="item in theitem" :key="item.id" :item=item :buttons='haveButtons' :comission= 'haveCommission' /> -->

    </div>
</template>

<script>
import Search from '../components/Search'
// import Item from '../components/Item'
import { mapState, mapGetters, mapActions} from 'vuex'
// import Search from '../components/Search.vue'
export default {
    components: {
        Search,
        // Item
    },
     data: () => ({
      phoneNumber: '',
    }),
    computed: {
        ...mapState([ 'userType']),
        ...mapGetters(['itemyPhone']),
        theitem() {
            //console.log(this.itemyPhone('07714967775'))
            return this.itemyPhone(this.phoneNumber)
        },
        haveButtons() {
         return this.userType == 'driver'? true : false
        },
        haveCommission () {
          return this.userType != 'dukandar'? true : false
        }
    },
    methods: {
        ...mapActions(['getTransportByPhone', 'resetSearchItemOrder']),
        onChildClick (value) {
            if (this.userType == 'admin') {
                this.getTransportByPhone(value)
               // console.log('Im admin');
            }
           
            else 
                this.phoneNumber= value
               // console.log(value);
        }
    },
    created() {
        this.resetSearchItemOrder()
    }

}
</script>

<style>

</style>