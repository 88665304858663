<template>
   <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          style="margin-top: 20px"
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          دروستكردني شار
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">شار</span>
        </v-card-title>
        <v-card-text>
          <city :item= item />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
          <!-- <v-btn
            color="blue darken-1"
            text
            @click="saveUser"
          >
            Save
          </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import City from '../components/City'
  export default {
    data: () => ({
      dialog: false,
      item: {
        cityName:''
      }
    }),
     components: {
      City
    },
    props: {
        user: {
            type: Object,
             default: function () {
                return { 
                    cityName: '',
                   
                 }
            }
        }
    },
    methods: {
        ...mapActions(['updatePassword']),
        saveCity() {
            this.addCity(this.user)
            this.dialog= false
        }
    },
    computed: {
       ...mapState([ 'userType']),
        isItAdmin() { 
        return this.userType == "admin"
      },
    }
  }
</script>