<template>
<div>
  <div class="noprint">
    <v-row  class="d-flex justify-center">
  <!-- <v-col> -->
      <!-- {{value}} - {{DriverItems}} -->
      <v-autocomplete
          v-model="value"
          :items="DriverItems"
          item-text="driverName"
          item-value="id"
          clearable
          filled
          rounded
          solo
          
  ></v-autocomplete>
  <!-- </v-col>
  <v-col> -->
  <v-btn
    class="ma-2"
        outlined
        color="indigo"
    @click="updateDriverIdClick"
  >Load</v-btn>
  <!-- </v-col> -->
  </v-row>
  </div>
    <Orders/>
</div>


</template>

<script>
import { mapState, mapActions } from "vuex"
import Orders from '../components/Orders'
export default {
components : {
  Orders,
},
data: () => ({
      items: Object,
      values: Object,
      value: null,
    }),
computed: {
    ...mapState(['driverList'])
    ,
    DriverItems () {
        return  this.driverList.map(x=> ({id: x.id, driverName: x.driverName}))
    },
    DriverValues () {
         return ['foo', 'bar']
    }   
    },
methods: {
        ...mapActions(['loadPosts', 'updateDriverId', 'resetDukandaeNameAndStatusForAdmin', 'loadDrivers']),
        updateDriverIdClick() {
        //console.log(this.value);
        this.updateDriverId(this.value)
        this.$store.dispatch('loadPosts');
        }
    },
    created() {
      this.resetDukandaeNameAndStatusForAdmin()
      this.loadDrivers()
    }
}
</script>

<style>
@media print
{
.noprint {display:none;}
}

@media screen
{
  
}
</style>