<template>
 <v-container class="grey lighten-5">

  <div v-if="isItAdmin">
    <city v-for="c in cities" :key="c.color" :item=c />
  </div>
 
 </v-container>
</template>

<script>
// this view will list all drivers name with password changing

  import City from '../components/City'
  import {mapState, mapActions} from 'vuex'
  export default {

    data () {
      return {
         selectedStatus: '',
         selection: 0,
          items: [
                {
                  color: '#1F7087',
                  src: "./taxi.png",
                  title: 'زانیاری شۆفێر',
                  artist: 'Foster the People',
                }
        ],
      }
    
    },
    methods: {
    ...mapActions(['resetDukandaeNameAndStatusForAdmin', 'loadCities'])
    },
    computed: {
       ...mapState(['token', 'userId', 'userType', 'cities']),
      
      isLoged () {
        return !!this.token
      },
      isItAdmin() { 
        return this.userType == "admin"
      },
      isItDriver() {
          return this.userType== 'driver'
      }
        
    },
    components: {
      City
    },
  
     created() {
    this.resetDukandaeNameAndStatusForAdmin()
    this.$store.dispatch('loadPosts');
    this.loadCities()
  },
  }
</script>
