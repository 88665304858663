<template>
<div>
  <div class="noprint">
      <v-row  class="d-flex justify-center">
    <!-- <v-col> -->
        <!-- {{value}} - {{DriverItems}} -->
        <!-- <p>  kjhjkhk</p> -->
        <v-autocomplete
            v-model="value"
            :items="DukanItems"
            item-text="dukandarName"
            item-value="id"
            clearable
            filled
            rounded
            solo
            
        ></v-autocomplete>
    <!-- </v-col>
    <v-col> -->
    <v-btn
      class="ma-2"
          outlined
          color="indigo"
      @click="updateDukandarIdClick"
    >Load</v-btn>
 
<!-- </v-col> -->
  </v-row>
 </div>
    <Orders/>
</div>


</template>

<script>
import { mapState, mapActions } from "vuex"
import Orders from '../components/Orders'
export default {
components : {
  Orders,
},
data: () => ({
      items: Object,
      values: Object,
      value: null,
    }),
computed: {
    ...mapState(['dukandarList'])
    ,
    DukanItems () {
        return  this.dukandarList.map(x=> ({id: x.id, dukandarName: x.dukandarName}))
    },
    DukanValues () {
         return ['foo', 'bar']
    }   
    },
methods: {
        ...mapActions(['loadPosts', 'setdukandaeNameAndStatusForAdmin', 'loadDDukandars']),
        updateDukandarIdClick() {
        //console.log(this.value);
        this.setdukandaeNameAndStatusForAdmin(this.value)
         this.$store.dispatch('loadPosts');
        }
    },
    created() {
      this.loadDDukandars()
    }
}
</script>

<style>

</style>