<template>
  <v-card
    max-width="400"
    class="mx-auto"
  >
   
    <!-- <v-app-bar
      dark
      color="pink"
    >
      <v-app-bar-nav-icon></v-app-bar-nav-icon>

      <v-toolbar-title>شوفيرەکان</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn icon>
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </v-app-bar> -->

    <v-container>
      <v-row dense>
       

        <v-col
         
          cols="12"
        >
          <v-card
            color="#1F7087"
            dark
          >
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title
                  class="text-h5"
                  text="زانیاری شار"
                ></v-card-title>

                <v-card-subtitle>

                     <v-text-field
                        label="شار"
                        v-model="item.city"
                    ></v-text-field>
                </v-card-subtitle>

                <v-card-actions>
                  

                  <v-btn
                    @click="updateTheCity"
                    class="ml-2 mt-5"
                    outlined
                    rounded
                    small
                  >
                   Save
                  </v-btn>
                       <!-- <v-btn
                    @click="cancel"
                    class="ml-2 mt-5"
                    outlined
                    rounded
                    small
                  >
                   Cancel
                  </v-btn> -->
                </v-card-actions>
              </div>

              <v-avatar
                class="ma-3"
                size="125"
                tile
              >
                <v-img src="/taxi.png" style="border-radius: 50%; "></v-img>
              </v-avatar>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
      <v-snackbar
      v-model="snackbar"
      color="primary"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="closeSnackbar"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import {mapActions, mapState} from 'vuex'
  export default {
    data: () => ({
     //snackbar: false,
     text: "تەواو",

    }),
    props: {
        item: Object,
    },
    computed: {
        ...mapState(['showSnackbar']),
        snackbar() {
            return this.showSnackbar
        }
    },
    methods: {
        ...mapActions(['updateTown']),
        updateTheCity() {
            this.updateTown({"id": this.item.id, "city": this.item.city})
        },
        closeSnackbar() {
            
        }
    }
  }
</script>