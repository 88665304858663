<template>
    <div>
          <v-autocomplete
            v-model="dukandarId"
            :items="DukandarItems"
            item-text="dukandarName"
            item-value="id"
            clearable
         
        
        ></v-autocomplete>
        <OrderItem  v-for="itemList in listForDukandar" :key="itemList.id" :orderItem=itemList />
        
    <v-btn
    style="float:left"
      class="mx-2"
      fab
      dark
      color="indigo"
      small
      @click="addEmptyToList"
    >
      <v-icon dark>
        mdi-plus
      </v-icon>
    </v-btn>
        <v-btn
            color="primary"
            elevation="2"
            outlined
           @click="save"
        >Save</v-btn>
    <v-snackbar
      v-model="snackbar"
     
      top
      timeout="2000"
    color="success"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">

        <v-btn

          color="red"
          text
          v-bind="attrs"
         
        >
          Saved!
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
    
        <v-card>
          <!-- <div class="container">   -->
          <v-toolbar  dark  color="primary">
            <v-btn  icon  dark  @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
               <v-spacer></v-spacer>
            <v-toolbar-title style="margin-left:10px">قائمە بۆ دوكاندار</v-toolbar-title>
            <v-toolbar-title>{{returnDukandarName}}</v-toolbar-title>
            <v-toolbar-title style="margin-right:10px">{{new Date().toJSON().slice(0,10).replace(/-/g,'/')}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn
                dark
                text
                @click="dialog = false"
              >
                Close
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
            <v-data-table
                :headers="headers"
                :items="listForDukandarForPrint"
                :items-per-page="-1"
                class="elevation-1"
                   :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-minus',
        nextIcon: 'mdi-plus',
           'items-per-page-text':'products per page'
      }"
              ></v-data-table>
      <!-- </div> -->
        </v-card>
      
    </v-dialog>




    </div>
  
</template>

<script>
import OrderItem from '../components/OrderItem'
import {mapActions, mapState, mapGetters} from 'vuex'
//import Confirm from '../components/Confirm.vue'
export default {
    data: () => ({
      dukandarId: null,
      text: "Saved Succfully!",
       dialog: false,
        notifications: false,
        sound: true,
        widgets: false,
     // snackbar: false
        headers: [
           { text: 'id', value: 'id' },
          {
            text: 'كريار',
            align: 'start',
            sortable: false,
            value: 'customer',
          },
          { text: 'شار', value: 'city' },
          
          { text: 'موبايل', value: 'mobile' },
          { text: 'پارە', value: 'price' },
          { text: 'كري', value: 'commisson' },
          { text: 'ناونيشان', value: 'address' },
          // { text: 'دوكاندار', value: 'dukandar' },
          // { text: 'ريكەوت', value: 'recievedDate' },
          // { text: 'شۆفير', value: 'driverName' },
          // { text: 'موبايلي', value: 'driverMobile' },
          
        ],
     
     
    }),
    components: {
        OrderItem
        //Confirm
    },
    computed: {
        ...mapState(['listForDukandar', 'dukandarList', 'showSnackbar', 'listForDukandarForPrint']),
        ...mapGetters(['getDukandarNameById']),
        DukandarItems () {
            return  this.dukandarList.map(x=> ({id: x.id, dukandarName: x.dukandarName}))
      
     },
       returnDukandarName() {
          return this.getDukandarNameById(this.dukandarId)
        },
     snackbar() {
         return this.showSnackbar
     }
    },
    methods: {
        ...mapActions(['addEmptyItemToDukandarList','loadCities', 'saveDukandarList',  'loadDDukandars', 'setIsItListForDukandarToTrue']),
        addEmptyToList() {
            this.addEmptyItemToDukandarList()
        },
        save() {
        this.saveDukandarList(this.dukandarId)
        this.dialog= true
        //this.snackbar=true
        },
        
    },
    mounted() {
        this.setIsItListForDukandarToTrue()
        this.loadDDukandars()
        this.loadCities()
    }
}
</script>

<style>

</style>