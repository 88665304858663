var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-autocomplete',{attrs:{"items":_vm.DukandarItems,"item-text":"dukandarName","item-value":"id","clearable":""},model:{value:(_vm.dukandarId),callback:function ($$v) {_vm.dukandarId=$$v},expression:"dukandarId"}}),_vm._l((_vm.listForDukandar),function(itemList){return _c('OrderItem',{key:itemList.id,attrs:{"orderItem":itemList}})}),_c('v-btn',{staticClass:"mx-2",staticStyle:{"float":"left"},attrs:{"fab":"","dark":"","color":"indigo","small":""},on:{"click":_vm.addEmptyToList}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1),_c('v-btn',{attrs:{"color":"primary","elevation":"2","outlined":""},on:{"click":_vm.save}},[_vm._v("Save")]),_c('v-snackbar',{attrs:{"top":"","timeout":"2000","color":"success"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"red","text":""}},'v-btn',attrs,false),[_vm._v(" Saved! ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.text)+" ")]),_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-spacer'),_c('v-toolbar-title',{staticStyle:{"margin-left":"10px"}},[_vm._v("قائمە بۆ دوكاندار")]),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.returnDukandarName))]),_c('v-toolbar-title',{staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(new Date().toJSON().slice(0,10).replace(/-/g,'/')))]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Close ")])],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.listForDukandarForPrint,"items-per-page":-1,"footer-props":{
    showFirstLastPage: true,
    firstIcon: 'mdi-arrow-collapse-left',
    lastIcon: 'mdi-arrow-collapse-right',
    prevIcon: 'mdi-minus',
    nextIcon: 'mdi-plus',
       'items-per-page-text':'products per page'
  }}})],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }