<template>
  <div>
      <invoice-item v-for="invoice2 in dukandarInvoice" :key="invoice2.myDate" :invoice=invoice2 :myUserIdProps=returnUserId />
  </div>
</template>

<script>
import InvoiceItem from '../components/InvoiceItem.vue'
import {mapActions, mapState} from 'vuex'
export default {
  components: { InvoiceItem },
  computed: {
      ...mapState(['userName', 'dukandarInvoiceState']),
      dukandarInvoice() {
          //console.log(this.dukandarInvoiceState);
        return this.dukandarInvoiceState
      },
      returnUserId() {
        if (this.$route.params.id == null || this.$route.params.id =='')
         return this.userName
        else
         return this.$route.params.id
      }
  },
  methods: {
      ...mapActions(['getDukandarInvoicesPaid'])
  },
  created() {
    if (this.$route.params.id == null || this.$route.params.id =='')
     this.getDukandarInvoicesPaid(this.userName)
      
    else
    this.getDukandarInvoicesPaid(this.$route.params.id)  
      
  }
}
</script>

<style>

</style>